import { runPortal } from '@piral/portal-core';
import { lazy } from 'react';
function setConfig(cfg) {
    Object.entries(cfg).forEach(([name, value]) => {
        window[name] = value;
    });
}
function start() {
    if (window.CLOUD_HAS_ERROR) {
        location.href = '/error?code=500';
    }
    else {
        const portalUrl = window.CLOUD_WWW_ADDRESS || document.location.origin;
        const apiUrl = window.CLOUD_API_ADDRESS || portalUrl;
        const consent = window.CLOUD_SHOW_CONSENT || false;
        runPortal({
            apiUrl,
            portalUrl,
            consent,
            components: {},
            providers: [window.CLOUD_AUTH_PROVIDER],
            pages: {
                '/': lazy(() => import('./Login')),
            },
            plans: ['premium'],
        });
    }
}
if (process.env.NODE_ENV === 'development') {
    const baseElement = document.head.appendChild(document.createElement('base'));
    baseElement.href = location.origin;
}
__webpack_public_path__ = document.querySelector('base').href;
fetch(`${__webpack_public_path__}api/v1/ui-config`)
    .then((res) => res.json())
    .then((cfg) => setConfig(cfg))
    .then(() => start());
